




import Vue from 'vue';
export default Vue.extend({
  mounted() {
    if (window.screen.width < 480) {
      this.$router.push({
        name: 'Mobile Discover',
      });
    } else {
      this.$router.push({
        name: 'Discover',
      });
    }
  },
});
